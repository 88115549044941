import { Box } from '@chakra-ui/react';
import { FC, useMemo } from 'react';

interface HighlightedWordProps {
  label: string;
  highlights?: {
    start: number;
    end: number;
  }[];
}

export const HighlightedWord: FC<HighlightedWordProps> = ({
  label,
  highlights,
}) => {
  if (!highlights || !highlights.length) {
    return <>{label}</>;
  }

  const parts = useMemo(() => {
    let currentIndex = 0;
    const parts: {
      text: string;
      highlighted: boolean;
    }[] = [];

    highlights.forEach((highlight) => {
      if (currentIndex < highlight.start) {
        parts.push({
          text: label.substring(currentIndex, highlight.start),
          highlighted: false,
        });
      }

      parts.push({
        text: label.substring(highlight.start, highlight.end),
        highlighted: true,
      });

      currentIndex = highlight.end;
    });

    if (label.length > currentIndex) {
      parts.push({
        text: label.substring(currentIndex),
        highlighted: false,
      });
    }

    console.log(label, parts);

    return parts;
  }, [label, highlights]);

  return (
    <>
      {parts.map((part) => {
        return (
          <Box
            cursor="pointer"
            as="span"
            bg={part.highlighted ? 'yellow' : 'none'}
          >
            {part.text}
          </Box>
        );
      })}
    </>
  );
};
