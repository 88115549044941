import { FilterInputGrouped } from '../generated/types';
import invert from 'lodash/invert';

const OPERATOR_QUERY_MAP: Record<string, string> = {
  '=': 'EQ',
};

const OPERATOR_QUERY_MAP_INVERTED = invert(OPERATOR_QUERY_MAP);

const FIELD_QUERY_MAP: Record<string, string> = {
  category: 'c',
  sub_categories: 'sc',
};

const FIELD_QUERY_MAP_INVERTED = invert(FIELD_QUERY_MAP);

export const fieldNameToQuery = (fieldName: string) =>
  FIELD_QUERY_MAP[fieldName] || fieldName;

export const queryToFieldName = (queryFieldName: string) =>
  FIELD_QUERY_MAP_INVERTED[queryFieldName] || queryFieldName;

export const operatorToQuery = (operator: string) =>
  OPERATOR_QUERY_MAP[operator] || operator;

export const queryToOperator = (queryOperator: string) =>
  OPERATOR_QUERY_MAP_INVERTED[queryOperator] || queryOperator;

export const filtersToQuery = (filters: FilterInputGrouped[]): string => {
  const parts: string[] = [];

  filters.forEach((filterGroup) => {
    filterGroup.conditions?.forEach((condition) => {
      parts.push(
        `${fieldNameToQuery(condition.field)}:${operatorToQuery(
          condition.operator
        )}:${condition.value}`
      );
    });
  });

  return parts.join('|');
};

export const queryToFilters = (query?: string): FilterInputGrouped[] => {
  if (!query || query === '') return [];

  const filterGroups: FilterInputGrouped[] = [];

  const parts = query.split('|');

  parts.forEach((part) => {
    const [fieldName, operator, value] = part.split(':');

    filterGroups.push({
      conditions: [
        {
          field: queryToFieldName(fieldName),
          operator: queryToOperator(operator),
          value,
        },
      ],
    });
  });

  return filterGroups;
};
