import { AES, enc } from 'crypto-js';
import {
  API_HASH_KEY,
  API_HASH_PREFIX,
  API_HASH_SUFFIX,
} from '../config/constants';

export function encrypt(text: string) {
  return AES.encrypt(
    `${API_HASH_PREFIX}||${text}||${API_HASH_SUFFIX}`,
    API_HASH_KEY
  ).toString();
}

export function decrypt(text: string) {
  return AES.decrypt(text, API_HASH_KEY);
}

export function isValid(text: string) {
  try {
    const decrypted = decrypt(text).toString(enc.Utf8);

    return Boolean(
      decrypted &&
        decrypted.startsWith(`${API_HASH_PREFIX}||`) &&
        decrypted.endsWith(`||${API_HASH_SUFFIX}`)
    );
  } catch (e) {
    return false;
  }
}
