import {
  ChangeEventHandler,
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Box,
  Flex,
  Button,
  Icon,
  Input,
  InputGroup,
  InputGroupProps,
  InputLeftElement,
  InputRightElement,
  Spinner,
  Select,
  useToast,
} from '@chakra-ui/react';
import { IoMdPin } from '@react-icons/all-files/io/IoMdPin';
import { useLazyQuery } from '@apollo/client';
import {
  AutocompleteQuery,
  AutocompleteQueryVariables,
  GeocodeQuery,
  GeocodeQueryVariables,
  GeocodeResultItemFragment,
} from '../../generated/types';
import { QueryAutocomplete } from '../../graphql/AutocompleteQuery';
import { QueryGeocode } from '../../graphql/GeocodeQuery';
import { useRouter } from 'next/router';
import { useCombobox } from 'downshift';
import Link from 'next/link';
import { IoChevronForward } from '@react-icons/all-files/io5/IoChevronForward';
import { addParamsToPath } from '../../utils/urls';
import { HighlightedWord } from './HighlightedWord';
import { encrypt } from '../../utils/crypto';

type LocationSearchProps = InputGroupProps & {
  value: string;
  onInputChange: ChangeEventHandler<HTMLInputElement>;
  onSubmit: () => void;
  allowEmptySubmit?: boolean;
  submitPath?: string;
  pathParams?: Record<string, string> | null;
};

type ApiAutocompleteItemHighlight = {
  start: number;
  end: number;
};

type ApiAutocompleteItem = {
  title: string;
  id: string;
  language: string;
  resultType: string;
  localityType: string;
  address: {
    label: string;
    countryCode: string;
    countryName: string;
    stateCode: string;
    state: string;
    countyCode: string;
    county: string;
    city: string;
    postalCode: string;
  };
  highlights?: {
    title?: ApiAutocompleteItemHighlight[];
  };
};

type ApiAutocompleteResult = {
  items: ApiAutocompleteItem[];
};

type ApiLookupResult = {
  title: string;
  id: string;
  resultType: string;
  address: {
    label: string;
    countryCode: string;
    countryName: string;
    stateCode: string;
    state: string;
    countyCode: string;
    county: string;
    city: string;
    postalCode: string;
  };
  position: {
    lat: number;
    lng: number;
  };
};

export const LocationSearch: FC<LocationSearchProps> = ({
  value,
  onInputChange,
  onSubmit,
  allowEmptySubmit,
  submitPath,
  pathParams,
  ...props
}) => {
  const router = useRouter();
  const timeoutRef = useRef<NodeJS.Timeout>();
  const [radius, setRadius] = useState(30);
  const [items, setItems] = useState<Array<ApiAutocompleteItem>>([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ApiAutocompleteResult | null>(null);
  const [lookupData, setLookupData] = useState<ApiLookupResult | null>(null);
  const toast = useToast();

  useEffect(() => {
    if (router.query.q) {
      setInputValue(router.query.q as string);
    }
  }, [router.query?.q]);

  const {
    isOpen,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    openMenu,
    inputValue,
    setInputValue,
    selectItem,
    selectedItem,
  } = useCombobox<ApiAutocompleteItem | null>({
    initialInputValue: (router.query.q as string) || '',
    onSelectedItemChange({ selectedItem }) {
      if (selectedItem) {
        doLookup(selectedItem);
      }
    },
    onInputValueChange({ inputValue, selectedItem }) {
      if (inputValue && inputValue.length >= 2) {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => {
          if (selectedItem?.title !== inputValue) {
            fetchData(inputValue);
          }
        }, 500);
      }
    },
    items,
    itemToString(item) {
      if (!item) return '';

      return item.title;
    },
  });

  const fetchData = useCallback(async (queryString: string) => {
    setLoading(true);
    const response = await fetch(
      `/api/geocode?query=${queryString}&key=${encodeURIComponent(
        encrypt(`${Date.now()}`)
      )}`
    );
    const json = await response.json();
    setLoading(false);

    if (json) {
      setData(json);
    }
  }, []);

  const doLookup = useCallback(async (selectedItem: ApiAutocompleteItem) => {
    setLoading(true);
    const response = await fetch(
      `/api/geocode?lookup=${selectedItem.id}&key=${encodeURIComponent(
        encrypt(`${Date.now()}`)
      )}`
    );
    const json = await response.json();
    setLoading(false);

    if (json) {
      setLookupData(json);
      // onSubmitHandler(selectedItem, json);
    }
  }, []);

  useEffect(() => {
    setItems(data?.items || []);

    if (inputValue.length <= 1) {
      setItems([]);
    }
  }, [data, inputValue]);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const onSubmitHandler = (
    selectedItem: ApiAutocompleteItem | null,
    lookupData: ApiLookupResult | null
  ) => {
    let newPath: string = '';
    if (lookupData && selectedItem) {
      newPath = addParamsToPath(submitPath || router.asPath, {
        lat: `${lookupData.position.lat.toFixed(3)}`,
        lng: `${lookupData.position.lng.toFixed(3)}`,
        radius: `${radius}`,
        q: selectedItem.title,
        ...pathParams,
      });
    } else {
      newPath = addParamsToPath(submitPath || router.asPath, {
        ...pathParams,
      });
    }

    router.push(newPath).then(() => {
      onSubmit && onSubmit();
    });
  };

  const isMenuOpen = isOpen && items.length > 0;

  const onChangeRadius = (val: string) => {
    setRadius(parseInt(val, 10));
  };

  return (
    <Flex width="100%" flex={1} {...getComboboxProps()} pos="relative">
      <Flex
        width="100%"
        minW={{
          base: '100%',
          md: '450px',
        }}
        flexDirection={{
          base: 'column',
          md: 'row',
        }}
      >
        <InputGroup {...props}>
          <InputLeftElement
            display={{
              base: 'none',
              md: 'flex',
            }}
            h="46px"
            pointerEvents="none"
            children={<IoMdPin color="gray.300" />}
          />
          <Input
            fontSize={{
              base: 'sm',
              md: 'md',
            }}
            borderColor="gray.400"
            placeholder="Stadt oder Ort suchen..."
            borderRadius="sm"
            borderBottomRadius={isMenuOpen ? 0 : 'sm'}
            borderRightRadius={0}
            borderRightWidth={{
              base: '1px',
              md: 0,
            }}
            h="46px"
            pl={{
              base: 3,
              md: 10,
            }}
            pr={{
              base: '90px',
              md: '160px',
            }}
            onFocus={(e) => {
              openMenu();

              if (inputValue !== '') {
                e.target.select();
              }
            }}
            {...getInputProps()}
          />
          <InputRightElement
            h="46px"
            width={{
              base: '90px',
              md: '160px',
            }}
          >
            {loading && (
              <Flex
                alignItems="center"
                justifyContent="center"
                w="40px"
                h="40px"
                p={2}
              >
                <Spinner color="primary" />
              </Flex>
            )}
            {!loading && (
              <Flex
                fontSize={{
                  base: 'xs',
                  md: 'sm',
                }}
                alignItems="center"
              >
                <Box
                  display={{
                    base: 'none',
                    md: 'block',
                  }}
                >
                  Umkreis
                </Box>
                <Select
                  onChange={(e) => {
                    onChangeRadius(e.target.value);
                  }}
                  value={`${radius}`}
                  size={{
                    base: 'xs',
                    md: 'sm',
                  }}
                  ml={1}
                  name="radius"
                  borderColor="transparent"
                >
                  <option value="10">10km</option>
                  <option value="20">20km</option>
                  <option value="30">30km</option>
                  <option value="50">50km</option>
                  <option value="100">100km</option>
                </Select>
              </Flex>
            )}
          </InputRightElement>
        </InputGroup>
        <Button
          mt={{
            base: 4,
            md: 0,
          }}
          px={6}
          bg="primary"
          color="white"
          borderRadius="sm"
          borderLeftRadius={0}
          _hover={{
            bg: 'primaryDark',
          }}
          _active={{
            bg: 'primaryDarker',
          }}
          h="46px"
          onClick={() => {
            if (selectedItem && lookupData) {
              onSubmitHandler(selectedItem, lookupData);
            } else if (allowEmptySubmit) {
              onSubmitHandler(null, null);
            } else {
              // Toast
              toast({
                title: 'Ort fehlt',
                description:
                  'Sie müssen einen Ort suchen und aus der Liste auswählen.',
                status: 'error',
                duration: 6000,
                isClosable: true,
              });
            }
          }}
        >
          Suchen
        </Button>
      </Flex>

      <Box
        {...getMenuProps()}
        pos="absolute"
        top="100%"
        left={0}
        right={0}
        mt="-1px"
        zIndex={14}
      >
        {isMenuOpen && (
          <Box
            bg="#fff"
            border="1px solid"
            borderColor="gray.400"
            borderTopColor="gray.200"
            borderBottomRadius="sm"
            boxShadow="md"
          >
            {items.map((item, index) => (
              <Box
                key={index}
                cursor="pointer"
                {...getItemProps({ item, index })}
              >
                <Box
                  fontWeight="bold"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  as="a"
                  py={2}
                  color="primaryDark"
                  borderBottomRadius={index === items.length - 1 ? 'xl' : 0}
                  px={4}
                  __css={{
                    bg: highlightedIndex === index && 'gray.100',
                  }}
                >
                  <Box
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                    overflow="hidden"
                    mr={2}
                  >
                    <HighlightedWord
                      label={item.title}
                      highlights={item.highlights?.title}
                    />
                  </Box>

                  <Icon as={IoChevronForward} />
                </Box>
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </Flex>
  );
};
