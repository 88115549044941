const PLACEHOLDER_URL = 'https://www.placeholder.de';

export const addParamsToPath = (
  path: string,
  params: Record<string, string | null>
) => {
  const url = new URL(`${PLACEHOLDER_URL}${path}`);

  if (url) {
    for (const param in params) {
      if (params[param] && params[param] !== '' && params[param] !== null) {
        url.searchParams.set(param, params[param] as string);
      } else {
        url.searchParams.delete(param);
      }
    }

    return url.toString().replace(PLACEHOLDER_URL, '');
  }

  return path;
};

export const removeParamsFromPath = (
  path: string,
  params: Record<string, string | string[]>,
  all: boolean
) => {
  const url = new URL(`${PLACEHOLDER_URL}${path}`);

  if (url) {
    url.searchParams.forEach((param) => {
      if (all || params[param]) {
        url.searchParams.delete(param);
      }
    });

    return url.pathname;
  }

  return path;
};
